const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://ethanaikman.xyz',
  title: 'EA.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Ethan Aikman',
  role: 'Aspiring Embedded Software Engineer',
  description:' Recent graduate of Mechatronics Engineering and Computer Science, with a passion in Embedded Software. Seeking a position as an Embedded Software Engineer, with a company that provides opportunities for both professional growth and personal development.',
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/ethan-aikman-6b73b323a/',
    github: 'https://github.com/bobomobo1',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Accel/Gyro STM32 for Nav Robot',
    description:
      'Implemented a C driver for MPU 6050 sensor on STM32F411RE, including UART/I2C drivers without libraries.',
    stack: ['C', 'STM32', 'Comms Protocols'],
    sourceCode: 'https://github.com/bobomobo1/MPU_6050-Driver',
  },
  {
    name: 'Particle Swarm Optimizer',
    description:
      'Developed a Python-based shortest route solver for the Traveling Salesman Problem using Particle Swarm Optimization.',
    stack: ['Python', 'AI'],
    sourceCode: 'https://github.com/bobomobo1/TSP-Solver-python',
  },
  {
    name: 'Housing Price Forecaster',
    description:
      'Developed a Windsor housing price forecaster using LSTM in Python/Jupyter, published findings in IEEE paper.',
    stack: ['Python', 'Jupyter', 'IEEE'],
    sourceCode: 'https://github.com/bobomobo1/Future-Windsor-Housing-Price-Forecasting',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'C',
  'C++',
  'Microcontrollers',
  'Python',
  'Git',
  'HTML',
  'CSS',
  'JavaScript',
  'React',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'ethanaikman2001@gmail.com',
}

export { header, about, projects, skills, contact }
